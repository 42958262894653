<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 사업장 -->
            <c-plant
              type="search"
              name="plantCd"
              v-model="searchParam.plantCd"
              @datachange="getList"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 평가년도 -->
            <c-datepicker
              :range="true" 
              type="year"
              label="LBL0001000"
              v-model="searchParam.assessmentYears"
              @datachange="yearchange"
            ></c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 평가기법 -->
            <c-select
              type="none"
              itemText="codeName"
              itemValue="code"
              name="ramTechniqueCd"
              label="LBL0001031"
              :comboItems="riskAssessmentItems"
              v-model="searchParam.ramTechniqueCd"
              @datachange="getList"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <!-- 위험도 기준 -->
            <c-select
              type="none"
              itemText="codeName"
              itemValue="code"
              name="afterRisk"
              label="LBL0001032"
              :comboItems="afterRiskItems"
              v-model="searchParam.afterRisk"
              @datachange="getList"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-6">
          <apexchart
            ref="riskLevelChart"
            height="380"
            type="line"
            :options="riskLevelChange.chartOptions"
            :series="riskLevelChange.series"
          >
          </apexchart>
        </div>
        <div class="col-6">
          <apexchart
            ref="imprLevelChart"
            height="380"
            type="line"
            :options="imprLevelChange.chartOptions"
            :series="imprLevelChange.series"
          >
          </apexchart>
        </div>
        <div class="col-12">
          <!-- 잔존 위험요인 리스트 -->
          <c-table
            ref="table"
            title="LBL0001033"
            :columns="gridColumns"
            :data="grid.data"
            :merge="gridMerge"
            :gridHeight="grid.height"
          >
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name==='customCol'">
                <component
                  :is="imprComponent"
                  :col="col"
                  :props="props"
                  :inputEditable="false"
                  :requestContentsCols="imprProps.requestContentsCols"
                  :tableKey="imprProps.tableKey"
                  :ibmTaskTypeCd="imprProps.ibmTaskTypeCd"
                  :ibmTaskUnderTypeCd="imprProps.ibmTaskUnderTypeCd"
                />
              </template>
              <template v-if="col.name==='afterRisk'">
                <div class="description-td">
                   <font class="text-negative" style="font-size:1.4em;font-weight:500;">{{props.row['afterRisk']}}</font>
                </div>
              </template>
              <template v-if="col.name === 'afterRiskEstimation'">
                <div class="description-td">
                   <font class="text-negative" style="font-size:1.4em;font-weight:500;">{{props.row['afterRiskEstimation']}}</font>
                </div>
              </template>
              <template v-else-if="col.name==='ramRiskLevelName'">
                <q-chip text-color="white" :style="`background-color:${props.row.riskColor};color:white;`">
                  {{props.row[col.name]}}
                </q-chip>
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from "@/js/selectConfig";
import VueApexCharts from "vue-apexcharts";
import riskScenarioMixin from './riskScenarioMixin'
export default {
  name: "risk-assessment-all-status",
  mixins: [riskScenarioMixin],
  components: {
    apexchart: VueApexCharts,
  },
  props: {},
  data() {
    return {
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      searchParam: {
        plantCd: null,
        assessmentYears: [],
        ramAssessTypeCd: null,
        ramTechniqueCd: 'RT00000025',
        processCd: null,
        ibmStepCd: null,
        afterRisk: '7',
      },
      grid: {
        colums: [],
        data: [],
        // height: '500px'
      },
      afterRiskItems: [
        { code: "1", codeName: "1 이상" },
        { code: "2", codeName: "2 이상" },
        { code: "3", codeName: "3 이상" },
        { code: "4", codeName: "4 이상" },
        { code: "5", codeName: "5 이상" },
        { code: "6", codeName: "6 이상" },
        { code: "7", codeName: "7 이상" },
        { code: "8", codeName: "8 이상" },
        { code: "9", codeName: "9 이상" },
        { code: "10", codeName: "10 이상" },
        { code: "11", codeName: "11 이상" },
        { code: "12", codeName: "12 이상" },
        { code: "13", codeName: "13 이상" },
        { code: "14", codeName: "14 이상" },
        { code: "15", codeName: "15 이상" },
        { code: "16", codeName: "16 이상" },
        { code: "17", codeName: "17 이상" },
        { code: "18", codeName: "18 이상" },
        { code: "19", codeName: "19 이상" },
        { code: "20", codeName: "20 이상" },
      ],
      riskLevelChange: {
        chartOptions: {
          title: {
            text: '위험도별 추세 변화'
          },
          annotations: {
            yaxis: [],
          },
          // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              title: {
                text: '위험도'
              },
              labels: {
                formatter: function(val) {
                  return Number.isInteger(val) ? val.toFixed(0) + '건' : val.toFixed(1) + '건';
                }
              }
            }
          ],
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          // stroke: {
          //   curve: 'smooth',
          // },
          // legend: {
          //   position: 'bottom',
          //   horizontalAlign: 'left',
          //   floating: true,
          //   offsetY: 10,
          //   offsetX: -5
          // },
        },
        series: [],
      },
      imprLevelChange: {
        chartOptions: {
          title: {
            text: '개선조치 추세 변화'
          },
          annotations: {
            yaxis: [],
          },
          // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true
          },
          labels: [],
          xaxis: {
          },
          yaxis: [
            {
              title: {
                text: '위험도/개선조치',
              },
              labels: {
                formatter: function(val) {
                  return Number.isInteger(val) ? val.toFixed(0) + '건': val.toFixed(1) + '건';
                }
              }
            }
          ],
          width: [0, 4],
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          // stroke: {
          //   curve: 'smooth',
          // },
          // legend: {
          //   position: 'bottom',
          //   horizontalAlign: 'left',
          //   floating: true,
          //   offsetY: 10,
          //   offsetX: -5
          // },
        },
        series: [
          {
            name: '위험요인 건수',
            type: 'column',
            data: [],
          },
          {
            name: '개선조치 건수',
            type: 'line',
            data: [],
          }
        ],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: "",
        visible: false,
        width: "80%",
        param: {},
        closeCallback: null,
      },
      editable: true,
      fullscreen: false,
      riskChangeUrl: "",
      riskAssessmentItems: [],
    };
  },
  computed: {
    residualRiskUrl() {
      let url = '';
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        url = selectConfig.ram.hazop.scenario.list.url
      }else if (this.searchParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        url = selectConfig.ram.jsa.scenario.list.url
      } else if (this.searchParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.scenario.list.url
      } else if (this.searchParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.scenario.list.url
      } else if (this.searchParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        url = selectConfig.ram.charm.scenario.result.url
      } 
      return url;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      //// role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.riskChangeUrl = selectConfig.ram.status.riskLevel.url;
      this.imprChangeUrl = selectConfig.ram.status.imprLevel.url;
      // code setting
      
      this.setLangLabel();
      this.$comm.getComboItems('RAM_TECHNIQUE_CD').then(_result => {
        this.riskAssessmentItems = this.$_.reject(_result, {attrVal1: 'N'})
      });

      this.searchParam.assessmentYears = [String(Number(this.$comm.getThisYear()) - 3), this.$comm.getThisYear()]
      this.yearchange();
      // list setting
    },
    setLangLabel() {
      let thisVue = this;
      this.riskLevelChange.chartOptions.yaxis[0].title.text = thisVue.$comm.getLangLabel('LBL0001011') // 위험도
      this.riskLevelChange.chartOptions.title.text = thisVue.$comm.getLangLabel('LBL0001035') // 위험도별 추세 변화
      this.riskLevelChange.chartOptions.yaxis[0].labels.formatter = (val) => {
        return Number.isInteger(val) ? val.toFixed(0) +  thisVue.$comm.getLangLabel('LBL0000389') : val.toFixed(1) +  thisVue.$comm.getLangLabel('LBL0000389'); //" 건"
      }
      this.imprLevelChange.series = [
      {
          name: this.$comm.getLangLabel('LBL0001039'), //위험요인 건수
          type: 'column',
          data: []
        },
        {
          name: this.$comm.getLangLabel('LBL0001038'), // 개선조치 건수
          type: 'line',
          data: []
        },
      ]
      this.imprLevelChange.chartOptions.yaxis[0].title.text = thisVue.$comm.getLangLabel('LBL0001037') // 위험도/개선조치
      this.imprLevelChange.chartOptions.title.text = thisVue.$comm.getLangLabel('LBL0001036') // 개선조치 추세 변화
      this.imprLevelChange.chartOptions.yaxis[0].labels.formatter = (val) => {
        return Number.isInteger(val) ? val.toFixed(0) +  thisVue.$comm.getLangLabel('LBL0000389') : val.toFixed(1) +  thisVue.$comm.getLangLabel('LBL0000389'); //" 건"
      }
    },
    yearchange() {
      if (!this.searchParam.assessmentYears || this.searchParam.assessmentYears.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSG0000370", // 평가년도를 선택하세요.
          type: "warning", // success / info / warning / error
        });
        return;
      }
      let startYear = Number(this.searchParam.assessmentYears[0]);
      let lastYear = Number(this.searchParam.assessmentYears[1]);

      this.riskLevelChange.chartOptions.xaxis.categories = [];
      this.imprLevelChange.chartOptions.labels = [];

      for (let year = startYear; year <= lastYear; year++) {
        if (year === startYear) {
          this.riskLevelChange.chartOptions.xaxis.categories.push(year);
          this.imprLevelChange.chartOptions.labels.push(year);
        } else if (year === lastYear) {
          this.riskLevelChange.chartOptions.xaxis.categories.push(year);
          this.imprLevelChange.chartOptions.labels.push(year);
        } else {
          this.riskLevelChange.chartOptions.xaxis.categories.push(year);
          this.imprLevelChange.chartOptions.labels.push(year);
        }
      }
      this.getList();
    },
    getList() {
      this.$set(this.imprLevelChange.series[0], 'name', this.$comm.getLangLabel('LBL0001034', {s1: this.searchParam.afterRisk })) // 위험도 {s1} 이상 건수
      this.riskChange();
      this.imprChange();
      this.residualRiskList();
    },
    riskChange() {
      this.riskLevelChange.series = [];
      this.$http.url = this.riskChangeUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        // series.data length 구하기
        let len = this.riskLevelChange.chartOptions.xaxis.categories.length;
        this.$_.forEach(_result.data, _item => {
          // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.riskLevelChange.chartOptions.xaxis.categories.indexOf(Number(_item.assessmentYear))
          if (this.$_.findIndex(this.riskLevelChange.series, { ramMatrixRiskStandardsId: _item.ramMatrixRiskStandardsId}) === -1) {
            this.riskLevelChange.series.push({
              ramMatrixRiskStandardsId: _item.ramMatrixRiskStandardsId,
              name: _item.riskContent,
              data: Array.from({length : len}, () => 0),
            })
          }
          this.riskLevelChange.series[this.$_.findIndex(this.riskLevelChange.series, 
          { ramMatrixRiskStandardsId: _item.ramMatrixRiskStandardsId })].data[idx] = _item.riskCount
        })
        setTimeout(() => {
          this.$refs["riskLevelChart"].refresh();
        }, 200);
      });
    },
    imprChange() {
      this.imprLevelChange.series[0].data = []
      this.imprLevelChange.series[1].data = []
      this.$http.url = this.imprChangeUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let length = this.imprLevelChange.chartOptions.labels.length;
          let idx = this.imprLevelChange.chartOptions.labels.indexOf(Number(_item.assessmentYear))
          this.imprLevelChange.series[0].data = new Array(length)
          this.imprLevelChange.series[1].data = new Array(length)
          let num = 0;
          this.$_.forEach(this.imprLevelChange.chartOptions.labels, _item => {
            this.imprLevelChange.series[0].data[num] = 0
            this.imprLevelChange.series[1].data[num] = 0

            let rsltIdx = this.$_.findIndex(_result.data, {assessmentYear: String(_item)}) 

            if (rsltIdx >= 0) {
              this.imprLevelChange.series[0].data[num] = _result.data[rsltIdx].riskCnt;
              this.imprLevelChange.series[1].data[num] = _result.data[rsltIdx].imprCnt;
            }
            num ++;     
          })
        })
        setTimeout(() => {
          this.$refs["imprLevelChart"].refresh();
        }, 200);
      })
    },
    residualRiskList() {
      // 선택된 위험성평가 정보가 있는 경우
      this.$http.url = this.residualRiskUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.grid.data = this.$_.reject(_result.data, {afterRisk: null})
      })
    },
  },
};
</script>